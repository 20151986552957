<template>
  <div class="industry">
    <div class="mod">
      <div class="industry_box">
        <div class="industry_top">
          <p>孵化多行业</p>
          <p>INDUSTRY</p>
        </div>
        <div class="industry_button">
          <div class="content_text">
            <p>对于各个行业</p>
            <p>
              我们会将根据您的行业所包含的特征，消费人群，消费形
              式等各方面进行调研考察，针对其采取不一样的助力方案，让
              您在消费者中焕然一新。而这会我们会将根据您的行业所包含
              的特征，消费人群，消费形式等各方面进行调研考察，针对其
              采取不一样的助力方案，让您在消费者眼中焕然一新。一样的
              助力方案，让您在消费者中焕然一新。而这会我们会将根据您
              的行业所包含的特征。
            </p>
          </div>
          <div class="content_spimg">
            <img src="@/assets/imgs/erce/sp.png" alt="" />
            <div class="iom">
              <img src="@/assets/imgs/erce/Video.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="showoff">
      <div class="showoff_inedx" v-for="(ilst, inedx) in content" :key="inedx">
        <img :src="ilst.img" alt="" />
        <div class="inedx_text">
          <img :src="ilst.imgi" alt="" />
          <p>{{ ilst.text }}</p>
          <p>{{ ilst.textp }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          img: require("@/assets/imgs/erce/mz.png"),
          imgi: require("@/assets/imgs/erce/shafa.png"),
          text: "美装",
          textp: "设计师品牌与大牌联名合作",
        },

        {
          img: require("@/assets/imgs/erce/fz.png"),
          imgi: require("@/assets/imgs/erce/fuzhuang.png"),
          text: "服装",
          textp: "设计师品牌与大牌联名合作",
        },

        {
          img: require("@/assets/imgs/erce/cy.png"),
          imgi: require("@/assets/imgs/erce/canyin.png"),
          text: "餐饮",
          textp: "更富含健康的餐食逐流简餐化",
        },

        {
          img: require("@/assets/imgs/erce/sf.png"),
          imgi: require("@/assets/imgs/erce/shafa.png"),
          text: "家具",
          textp: "海外供应商强推进让家更温暖",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.industry {
  width: 100%;
  .mod {
    overflow: hidden;
    width: 100%;
    background: #313337;
    height: 750px;
    .industry_box {
      max-width: 1200px;
      margin: 0 auto;
      height: 600px;
      margin-top: 80px;
      .industry_top {
        text-align: center;
        margin-bottom: 50px;
        p:nth-child(1) {
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 20px;
        }
        p:nth-child(2) {
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 25px;
        }
      }
      .industry_button {
        .content_text {
          width: 578px;
          float: left;
          p:nth-child(1) {
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #f2f2f2;
            margin-bottom: 35px;
          }
          p:nth-child(2) {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 300;
            letter-spacing: 1px;
            color: #f2f2f2;
            line-height: 30px;
          }
        }
        .content_spimg {
          width: 570px;
          background: grey;
          float: right;
          overflow: hidden;
          border-radius: 10px;
          position: relative;
          .iom {
            margin: 0 auto;
            position: absolute;
            top: 130px;
            left: 245px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .showoff {
    max-width: 1200px;
    margin: 0 auto;
    height: 300px;
    display: flex;
    justify-content: space-between;
    margin-top: -125px;
    .showoff_inedx {
      width: 250px;
      height: 250px;
      border-radius: 20px;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      .inedx_text {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.2);
        display: none;
        img {
          width: 50px;
          height: 45px;
        }
        p:nth-child(2) {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
          margin-top: 20px;
          margin-bottom: 25px;
        }
        p:nth-child(3) {
          font-size: 16px;
          text-align: center;
          font-family: PingFang SC;
          line-height: 20px;
          font-weight: 200;
          width: 128px;
          color: #ffffff;
        }
      }
    }
    .showoff_inedx:hover .inedx_text {
      display: block;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>

