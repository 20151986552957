<template>
  <div class="toding">
    <div class="toding_box">
      <div class="toding_top">
        <p>了解我们</p>
        <p>TO UNDERSTANDING</p>
      </div>
      <div class="toding_button">
        <div class="content_top">
          <div class="inedx_left">
            <img src="@/assets/imgs/erce/Iuxw.png" alt="" />
          </div>
          <div class="inedx_right">
            <p>我们能做到</p>
            <p>
              我们公司业务所涵盖的孵化领域有美妆，服饰，餐饮，家具，酒
              店，小程序，app等。
            </p>
            <button>点击了解</button>
          </div>
        </div>
        <div class="content_button">
          <div class="inedx_right">
            <p>联系我们</p>
            <p>
              我们公司业务所涵盖的孵化领域有美妆，服饰，餐饮，家具，酒
              店，小程序，app等。
            </p>
            <button>点击了解</button>
          </div>
          <div class="inedx_left">
            <img src="@/assets/imgs/erce/unsplash.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
    };
  },
};
</script>
<style scoped lang="less">
.toding {
  width: 100%;
  overflow: hidden;
  margin-top: 110px;
  .toding_box {
    max-width: 1200px;
    margin: 0 auto;
    .toding_top {
      text-align: center;
      margin-bottom: 90px;
      p:nth-child(1) {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        margin-bottom: 25px;
      }
    }
    .toding_button {
      .content_top {
        height: 445px;
        .inedx_left {
          float: left;
        }
        .inedx_right {
          float: right;
          margin-top: 70px;
          text-align: center;
          p:nth-child(1) {
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            margin-bottom: 35px;
          }
          p:nth-child(2) {
            text-indent: 70px;
            letter-spacing: 2px;
            font-size: 22px;
            text-align: left;
            font-family: Source Han Sans CN;
            font-weight: 400;
            width: 385px;
            color: #666666;
            line-height: 36px;
          }
          button {
            width: 156px;
            height: 42px;
            border: 1px solid #333333;
            border-radius: 10px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            outline: none;
            margin-top: 75px;
          }
        }
      }
      .content_button {
        height: 445px;
        .inedx_left {
          float: left;
        }
        .inedx_right {
          float: left;
          margin-top: 70px;
          text-align: center;
          p:nth-child(1) {
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            margin-bottom: 35px;
          }
          p:nth-child(2) {
            text-indent: 70px;
            letter-spacing: 2px;
            font-size: 22px;
            text-align: left;
            font-family: Source Han Sans CN;
            font-weight: 400;
            width: 385px;
            color: #666666;
            line-height: 36px;
          }
          button {
            width: 156px;
            height: 42px;
            border: 1px solid #333333;
            border-radius: 10px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            outline: none;
            margin-top: 75px;
          }
        }
      }
    }
  }
}
</style>

