<template>
  <div class="push"></div>
</template>

<script>
export default {
  data() {
    return {
      content: [],
    };
  },
};
</script>
<style scoped lang="less">
.push {
  width: 100%;
  height: 420px;
  background: url("../../../../assets/imgs/erce/zhuliupingtai.png") center
    no-repeat;
}
</style>

