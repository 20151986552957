<template>
  <!-- baner -->
  <div class="uncover">
    <div class="tetx_ilo">
      <i class="i"></i>
      <i class="i"></i>
      <p>
        我们的SAAS平台将帮助每个人进入电商新世代，使我们更接近网络电商新战线。
      </p>
    </div>
    <div class="uncover_box">
      <div class="content_top">
        <div class="top_inedx">
          <img src="@/assets/imgs/erce/dsxsd.png" alt="" />
        </div>
        <div class="text_inedx">
          <p>电商新时代帷幕已经<br />揭开</p>
          <p>
            我们旗下拥有:网红孵化、电商孵化、品推
            、微站、广告助手等解决方案，帮助营效
            率和盈利能力。昆仑虚精准营销业营销自
            动化等技术及优质媒体源，为广投放服务 为商户提供更多。
          </p>
        </div>
      </div>
      <div class="content_bottom">
        <div class="nav_indix">
          <p class="tnps">SAAS-电商孵化</p>
          <div
            class="list"
            v-for="(imet, index) in content"
            :key="index"
            @mouseover="active = index"
          >
            <i v-show="active == index"></i>
            <p
              :style="{
                paddingLeft: active === index ? '15px' : '0',
                color: active === index ? '#333333' : '#666666',
                fontSize: active === index ? '24px' : '22px',
              }"
            >
              {{ imet.text }}
            </p>
          </div>
        </div>
        <div class="bottom_indix">
          <div
            v-for="(imet, index) in content"
            :key="index"
            v-show="active == index"
          >
            <img
              v-for="(imet, index) in imet.cont"
              :key="index"
              :src="imet.img"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      content: [
        {
          text: "SAAS系统平台",
          cont: [
            {
              img: require("@/assets/imgs/erce/saasxz.png"),
            },
          ],
        },
        {
          text: "专业品牌设计",
          cont: [
            {
              img: require("@/assets/imgs/erce/dsxsd.png"),
            },
          ],
        },
        {
          text: "实时产品定位",
          cont: [
            {
              img: require("@/assets/imgs/erce/saasxz.png"),
            },
          ],
        },
        {
          text: "资深团队运营",
          cont: [
            {
              img: require("@/assets/imgs/erce/saasxz.png"),
            },
          ],
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.uncover {
  width: 100%;
  overflow: hidden;
  .tetx_ilo {
    margin-top: 80px;
    .i::after {
      content: "";
      display: inline-block;
      width: 158px;
      height: 2px;
      background: #333333;
    }
    .i:nth-child(1) {
      float: left;
    }
    .i:nth-child(2) {
      float: right;
    }
    p {
      text-align: center;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }
  .uncover_box {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 95px;
    .content_top {
      width: 100%;
      height: 300px;
      margin-bottom: 100px;
      .top_inedx {
        float: left;
        img {
          width: 630px;
        }
      }
      .text_inedx {
        float: right;
        p:nth-child(1) {
          font-size: 40px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          margin-bottom: 40px;
          line-height: 45px;
        }
        p:nth-child(2) {
          width: 400px;
          font-size: 22px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #444444;
          line-height: 34px;
        }
      }
    }
    .content_bottom {
      width: 100%;
      height: 400px;
      .nav_indix {
        float: left;
        margin-left: 150px;
        .tnps {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
             margin-bottom: 45px;
        }
        .list {
          position: relative;
       
          i {
            display: inline-block;
            width: 2px;
            height: 28px;
            background: #333333;
            position: absolute;
            left: 0;
            top: 14px;
          }
          p {
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
             line-height: 55px;
            letter-spacing: 2px;
          }
        }
      }
      .bottom_indix {
        float: right;
        img {
          width: 600px;
        }
      }
    }
  }
}
</style>

