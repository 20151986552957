<template>
  <div>
    <Brand />
    <Uncover />
    <Industry />
    <Toding />
    <Push />
  </div>
</template>

<script>
import Brand from "@/components/pagespers/program/commerce/Brand.vue";
import Uncover from "@/components/pagespers/program/commerce/Uncover.vue";
import Industry from "@/components/pagespers/program/commerce/Industry.vue";
import Toding from "@/components/pagespers/program/commerce/Toding.vue";
import Push from "@/components/pagespers/program/commerce/Push.vue";

export default {
  components: {
    Brand,
    Uncover,
    Industry,
    Toding,
    Push
  },

  data() {
    return {};
  },
};
</script>

<style scoped lang="less"></style>
